

















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import MButton from '@/components/common/MButton.vue'
import MModalCard from '@/components/common/MModalCard.vue'
import { ButtonType } from './types'

@Component({
  components: { MButton, MModalCard }
})
export default class MConfirmationDialog extends Vue {
  @Prop() title!: string
  @Prop() text!: string
  @Prop({ default: 'OK' }) confirmText!: string
  @Prop({ required: true }) type!: ButtonType
}

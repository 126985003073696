export class AuthRequest {
  username: string
  password: string

  constructor(username: string, password: string) {
    this.username = username
    this.password = password
  }
}

export class SignupRequest {
  username: string
  password: string
  budgetName: string

  constructor(username: string, password: string, budgetName: string) {
    this.username = username
    this.password = password
    this.budgetName = budgetName
  }
}

export class AuthResponse {
  token: string | null
  error: string | null
  status: number

  constructor(token: string | null, error: string | null, status: number) {
    this.token = token
    this.error = error
    this.status = status
  }
}

export class PublicUserInfo {
  id: string
  name: string

  constructor(id: string, name: string) {
    this.id = id
    this.name = name
  }
}

export class Budget {
  id: string
  name: string
  users: PublicUserInfo[]

  constructor(id: string, name: string, users: PublicUserInfo[]) {
    this.id = id
    this.name = name
    this.users = users
  }
}

export class UserInfo {
  id: string
  username: string
  budgets: Budget[]

  constructor(id: string, username: string, budgets: Budget[]) {
    this.id = id
    this.username = username
    this.budgets = budgets
  }
}

export class CreateBudget {
  name: string

  constructor(name: string) {
    this.name = name
  }
}

export class AddUserRequest {
  budgetId: string
  username: string

  constructor(budgetId: string, username: string) {
    this.budgetId = budgetId
    this.username = username
  }
}

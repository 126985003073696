





























































































import Vue from 'vue'
import { userModule } from '@/store/user/module'
import Component from 'vue-class-component'
import axios from '@/backend/axios'
import { UserInfo, CreateBudget, AddUserRequest } from '@/store/user/types'
import { Watch } from 'vue-property-decorator'
import MButton from '@/components/common/MButton.vue'
import MTextField from '@/components/common/MTextField.vue'
import MConfirmationDialog from '@/components/common/MConfirmationDialog.vue'
import { ButtonType } from '@/components/common/types'

@Component({
  components: { MButton, MTextField, MConfirmationDialog }
})
export default class StartView extends Vue {
  users = userModule
  newBudgetName: string | null = null
  addUsername: string[] = []
  transactionsFile: File | null = null
  budgetsFile: File | null = null
  budgetIdForUpload: string | null = null
  budgetToDelete: string | null = null
  showDeleteDataDialog: boolean = false

  ButtonType = ButtonType

  mounted(): void {
    this.users.retrieveUserInfo()
  }

  get userInfo(): UserInfo | null {
    return this.users.userInfo
  }

  startingRoute(budgetId: string): string {
    return `/${budgetId}/transactions?filterStatuses=UNCLEARED,CLEARED,RECONCILED`
  }

  onCreateBudget(): Promise<UserInfo | { close: () => any }> | undefined {
    if (this.newBudgetName) {
      const budgetName = this.newBudgetName
      this.newBudgetName = null
      return this.users
        .createBudget(new CreateBudget(budgetName))
        .then(() => this.users.refreshToken())
        .then(() => this.users.retrieveUserInfo())
        .catch(error =>
          this.$buefy.toast.open({
            message: 'Error creating budget: ' + error,
            type: ':type="ButtonType.DANGER"'
          })
        )
    }
  }

  onAddUser(
    budgetIndex: number
  ): Promise<UserInfo | { close: () => any }> | undefined {
    if (this.addUsername[budgetIndex] && this.userInfo) {
      const usernameToAdd = this.addUsername[budgetIndex]
      this.addUsername[budgetIndex] = ''
      return this.users
        .addUser(
          new AddUserRequest(
            this.userInfo.budgets[budgetIndex].id,
            usernameToAdd
          )
        )
        .then(() => this.users.retrieveUserInfo())
        .catch(error =>
          this.$buefy.toast.open({
            message: 'Error adding user: ' + error,
            type: ':type="ButtonType.DANGER"'
          })
        )
    }
  }

  @Watch('transactionsFile')
  onTransactionsFileUpload(newValue: File): void {
    var data = new FormData()
    data.append('file', newValue)
    axios
      .post(`${this.budgetIdForUpload}/ynab-import-transactions`, data)
      .then(() => this.$buefy.toast.open('Upload complete'))
      .catch(error =>
        this.$buefy.toast.open({
          message: 'Upload error: ' + error,
          type: ':type="ButtonType.DANGER"'
        })
      )
  }

  @Watch('budgetsFile')
  onBudgetsFileUpload(newValue: File): void {
    var data = new FormData()
    data.append('file', newValue)
    axios
      .post(`${this.budgetIdForUpload}/ynab-import-budgets`, data)
      .then(() => this.$buefy.toast.open('Upload complete'))
      .catch(error =>
        this.$buefy.toast.open({
          message: 'Upload error: ' + error,
          type: ':type="ButtonType.DANGER"'
        })
      )
  }

  async onConfirmDeleteBudgetDataClicked(
    budgetId: string
  ): Promise<{ close: () => any }> {
    return axios
      .post(`${budgetId}/delete-data`)
      .then(() => this.$buefy.toast.open('Data deleted'))
      .catch(error =>
        this.$buefy.toast.open({
          message: 'Deletion error: ' + error,
          type: ':type="ButtonType.DANGER"'
        })
      )
  }

  get showDeleteBudgetDialog(): boolean {
    return !!this.budgetToDelete
  }

  set showDeleteBudgetDialog(value: boolean) {
    if (!value) {
      this.budgetToDelete = null
    }
  }
}
